/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

:root {
    --amplify-primary-color: #3F51B5;
    --amplify-primary-tint: #5C6BC0;
    --amplify-primary-shade: #7986CB;
  }
